<template>
    <div class="panel" id="ReportContent">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column has-text-left is-2" v-show="getDivWidth('ReportContent') < 1300">
                    <div class="button is-primary has-text-white is-small"  @click="newProductUpdate">
                        <i class="fas fa-barcode"></i>&nbsp;&nbsp;Scan Products
                    </div>
                    <!-- <b-button title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                        <i class="fas fa-print"></i>
                    </b-button> -->
                </div>
                <div class="column is-size-10 has-text-centered">PRODUCT UPDATES HISTORY</div>
                <div class="column has-text-right is-2" v-show="getDivWidth('ReportContent') >= 1300">
                    <div class="button is-primary has-text-white is-small"  @click="newProductUpdate">
                        <i class="fas fa-file-invoice-dollar"></i>&nbsp;&nbsp;Scan Products
                    </div>
                    <!-- <b-button title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                        <i class="fas fa-print"></i>
                    </b-button> -->
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-4 has-text-left">
                <b-field>
                    <date-range-selector
                        :dateRange="salesDateRange"
                        @set-date-range="setDateRange"
                        >
                    </date-range-selector>                    
                </b-field>                    
            </div>

            <div class="column">
                <br>
                <b-switch
                    true-value="PENDING"
                    false-value="ALL"
                    size="is-small"
                    v-model="synchedStatusFilter"
                    @input="fetchRecords"
                    type="is-success">
                    Pending
                </b-switch>
                <b-switch
                    true-value="SUCCESS"
                    false-value="ALL"
                    size="is-small"
                    v-model="synchedStatusFilter"
                    @input="fetchRecords"
                    type="is-success">
                    Success
                </b-switch>
                <b-switch
                    true-value="FAILURE"
                    false-value="ALL"
                    size="is-small"
                    v-model="synchedStatusFilter"
                    @input="fetchRecords"
                    type="is-success">
                    Failed
                </b-switch>
                <!-- <b-switch
                    true-value="CANCELLED"
                    false-value="ALL"
                    size="is-small"
                    v-model="synchedStatusFilter"
                    @input="fetchRecords"
                    type="is-success">
                    Canceled
                </b-switch> -->
                <b-switch
                    true-value="ALL"
                    false-value="PENDING"
                    size="is-small"
                    v-model="synchedStatusFilter"
                    @input="fetchRecords"
                    type="is-success">
                    All
                </b-switch>
                
            </div>
        </div>

        <b-table
            class="has-text-weight-semibold"
            :visible="!isLoading"
            :data='groupedProductUpdates'
            striped
            :scrollable="isScrollable"
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort="createdAt"
            default-sort-direction="desc"
            :show-detail-icon="showDetailIcon"
            detailed
            detail-key="correlationId"            
            >
            <template>
                <b-table-column field="posSynched" label="cancel" v-slot="props" v-if="synchedStatusFilter == 'PENDING'">
                    <b-button 
                        size="is-small"
                        type="is-danger"
                        rounded
                        outlined
                        icon-left="ban"
                        @click="cancelUpdate(props.row)"
                        ></b-button>
                </b-table-column>
                <b-table-column field="createdAt" label="Updated Time" v-slot="props" sortable sorticon>
                    {{ props.row.createdAt |formatDateTime }} 
                </b-table-column>
                <b-table-column field="userName" label="Updated By" v-slot="props" sortable sorticon>
                    {{ props.row.userName  }} 
                </b-table-column>
                 <b-table-column field="effectiveDate" label="Effective Date" v-slot="props" sortable sorticon>
                    {{ props.row.effectiveDate | formatDate  }}
                </b-table-column>
                <b-table-column field="productId" label="Products Updated" v-slot="props" sortable sorticon>
                    {{ props.row.products.length  }}
                </b-table-column>                
                <b-table-column field="effectiveDate" label="POS Update Status" v-slot="props" v-if="synchedStatusFilter == 'ALL'">
                    {{ statusCount(props.row)  }}
                </b-table-column>
            </template>
            <template slot="detail" slot-scope="props">
              <b-table 
                :data="props.row.products" 
                >
                <template>
                    <!-- <b-table-column class="is-2 has-text-right" v-slot="props">
                        <b-tooltip position="is-right" label="Click on button to Print Label" dashed>
                            <b-button v-show="!props.row.printFlag" class="is-primary has-text-default is-small" @click="setPrintLabel(props.row)" outlined rounded>
                            </b-button>
                        </b-tooltip>
                        <b-tooltip type="is-success" position="is-right" label="Enabled to Print Label" dashed>    
                            <b-button v-show="props.row.printFlag" class="is-primary is-small has-text-success" @click="setPrintLabel(props.row)" outlined rounded>
                                <i class="fas fa-print"></i>
                            </b-button>            
                        </b-tooltip> 
                    </b-table-column> -->
<!-- 
                    <b-table-column field="productId" label="Id" v-slot="props">
                        {{ props.row.productId  }}
                    </b-table-column> -->
                    <b-table-column field="productUpc" label="UPC" v-slot="props">
                        {{ props.row.productUpc  }}
                    </b-table-column>
                    <b-table-column field="productName" label="Name" v-slot="props">
                        {{ props.row.productName  }}
                    </b-table-column>
                    <!-- <b-table-column field="productDesc" label="Desc" v-slot="props">
                        {{ props.row.productDesc  }}
                    </b-table-column> -->
                    <b-table-column field="departmentName" label="Department" v-slot="props">
                        {{ props.row.departmentName  }}
                    </b-table-column>
                    <!-- <b-table-column field="departmentStoreMapping" label="Mapping" v-slot="props">
                        {{ props.row.departmentStoreMapping  }}
                    </b-table-column> -->
                    <b-table-column field="beforeCost" label="Previous Cost" v-slot="props">
                        {{ props.row.beforeCost | formatCurrency  }}
                    </b-table-column>
                    <b-table-column field="afterCost" label="New Cost" v-slot="props">
                        {{ props.row.afterCost | formatCurrency }}
                    </b-table-column>
                    <!-- <b-table-column numeric  field="averageCost" label="Cost" v-slot="props">
                        {{ (props.row.averageCost ? props.row.averageCost : 0) | formatCurrency }}
                    </b-table-column> -->
                    <!-- <b-table-column numeric  field="suggestedSellPrice" label="Suggested Price" v-slot="props"  :class="{'has-text-danger':  props.row.suggestedSellPric > props.row.afterPrice }">
                        {{ (props.row.suggestedSellPrice ? props.row.suggestedSellPrice : 0) | formatCurrency }} 
                    </b-table-column>                     -->
                    <b-table-column numeric  field="beforePrice" label="Previous Price" v-slot="props">
                        {{ (props.row.beforePrice ? props.row.beforePrice : 0) | formatCurrency }} 
                    </b-table-column>
                    <b-table-column numeric field="afterPrice" label="New Price" v-slot="props">
                        {{ props.row.afterPrice | formatCurrency }} 
                    </b-table-column>
                    <b-table-column field="effectiveDate" label="Effective Date" v-slot="props" sortable sorticon>
                        {{ props.row.effectiveDate | formatDate  }}
                    </b-table-column>
                    <b-table-column field="synched" label="Status" v-slot="props" sortable sorticon>
                        {{ props.row.synched  }}
                    </b-table-column>

                </template>
              </b-table>
            </template>
            <template slot="bottom-left">
                <div class="is-size-7 m-5">
                    Last updated: {{ new Date() | formatDateTime }} 
                </div>
            </template>
        </b-table>
        <product-scan
            ref="ProductScan"
            :isShowEditDialog="isShowEditDialog"
            :departments="departments"
            :user="user"
            @hide-dialog="closeDialog"
        >

        </product-scan>
    </div>
</template>

<script>

import axios from 'axios'
import moment from 'moment'
import DateRangeSelector from '../../../../components/app/DateRangeSelector.vue'
import UserStore from '../../../../store/UserStore'
import ProductScan from './ProductScan'

export default {

    components: {
        DateRangeSelector,
        ProductScan
    },

    data() {
        return {
            salesDateRange: [moment().startOf('month').toDate(), new Date],
            page: 0,
            perPage: 25,
            sortField: 'sale.closingDate',
            sortOrder: 'desc',
            user: UserStore.getters.getUser,
            productUpdates: [],
            departments: [],
            groupedProductUpdates: [],
            isLoading: false,
            isScrollable: false,
            isPaginated: true,
            showDetailIcon: true,
            isShowPDFDialog: false,
            synchedStatusFilter: "PENDING",
            isShowEditDialog: false,
            upcOnlineData: {},
            timer: 0
        }
    },

    methods: {

        async fetchRecords() {

            this.productUpdates = []
            this.groupedProductUpdates = []
            var startDate = this.salesDateRange[0].toISOString().split('T')[0]
            var endDate = this.salesDateRange[1].toISOString().split('T')[0]
            var url = process.env.VUE_APP_API_ROOT_URL + 'stores/' + this.user.activeStore +
                    "/transactions/search?"

            var showPage = this.page - 1
            if (this.isShowPDFDialog) {
                url = url + "page=0&size=1M"
            } else {
                // this.perPage = this.setPerPage
                url = url + "page="+showPage+"&size=" + this.perPage
            } 
            if (this.synchedStatusFilter != "ALL") {
                url = url +"&synched=" + this.synchedStatusFilter
            }

            if (this.sortField == "") {
                this.sortField = "sale.closingDate"
            }
            if (this.sortOrder == "") {
                this.sortOrder = "desc"
            } 
            // url = url + "&sort="+this.sortField+","+this.sortOrder
            url = url + "&effectiveDateFrom="+startDate+"&effectiveDateTo="+endDate

            await axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    this.productUpdates = response.data.data
                    this.groupByCorrelationId()
                })

        },

        async fetchDepartments() {
            this.departments = []
            var url = process.env.VUE_APP_API_ROOT_URL + 'stores/' + this.user.activeStore +
                    "/departments"
            await axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    this.departments = this.sortArrayByProp(response.data.data,"name")
                })
        },

        async updateUserTransaction(transaction) {
            var url = process.env.VUE_APP_API_ROOT_URL + 'stores/' + this.user.activeStore +
                    "/device/transactions/status"

            var transactionObj = [
                    {
                        "correlationId": transaction.correlationId,
                        "posSynched": transaction.posSynched
                    }
                    ]
            await axios.post(url,transactionObj,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( () => {
                    this.fetchRecords()
                })
        },

        async fetchUpcOnlineData(upc) {
            if (upc) {
                var url = "/upc/product/" + upc

                await axios.get(url, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Authorization': 'Bearer ' + '118CF10D8C5105CA1BCC979E375319DF' 
                    }
                })
                .then(response => {
                    this.upcOnlineData = response.data
                })
                .catch(err => {
                    console.log(err)
                })
            }

        },
        
        groupByCorrelationId() {
            this.groupedProductUpdates = []
            var sortedUpdates = this.productUpdates.sort((b,a) =>  b.correlationId - a.correlationId )
            sortedUpdates.forEach(item => {
                
                var index = this.groupedProductUpdates.findIndex((map) => map.correlationId === item.correlationId)
                if (index > -1) {
                    this.groupedProductUpdates[index].products.push(item)
                } else {
                    var group = {
                        "correlationId": item.correlationId,
                        "createdAt": item.createdAt,
                        "userName": item.userName,
                        "effectiveDate": item.effectiveDate,
                        "synched": this.toTitleCase(item.synched),
                        "products": [item]
                    }
                    this.groupedProductUpdates.push(group)
                }

            })
        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        statusCount(row) {
            var success = 0
            var failed = 0
            var pending = 0
            var canceled = 0
            row.products.forEach(product => {
                switch (product.synched) {
                    case "SUCCESS":
                        success++
                        break
                    case "FAILURE":
                        failed++
                        break
                    case "CANCELLED":
                        canceled++
                        break
                    case "PENDING":
                        pending++
                        break

                }
            })

            return "Pending: " + pending + ", Success: " + success + ", Failed: " + failed + ", Cancelled: " + canceled

        },

        newProductUpdate() {
            this.isShowEditDialog = true
            this.$refs.ProductScan.openDialog()
        },

        cancelUpdate(row) {
            row.posSynched = "CANCELLED"
            this.updateUserTransaction(row)
        },

        closeDialog() {
            this.isShowEditDialog = false
            this.fetchRecords()
        },

        setPrintLabel(row) {
            row.printFlag=!row.printFlag
        },

    },

    created() {
        this.timer = setInterval(this.fetchRecords, 60000 * 3);
    },

    watch: {
        salesDateRange() {
            this.fetchRecords()
        }
    },

    mounted() {
        this.fetchDepartments()
        this.fetchRecords()
    }
    
}
</script>

<style>
.veecli-table {
    border-bottom: 0.5px solid gray;
    padding-bottom: 2.5em;
    height: 2em;
}
</style>